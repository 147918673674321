import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/styles'
import { BackstageTheme } from '@backstage/theme'
import tridonicIcon from './logo/tridonic-icon.png'
import tridonicWhiteIcon from './logo/tridonic-white-icon.png'

const useStyles = makeStyles({
  img: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: '#7df3e1',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  const themeInUse = (useTheme() as BackstageTheme).palette.type

  if (themeInUse === "light") {
    return <img className={classes.img} src={tridonicIcon} />;
  }
  else {
    return <img className={classes.img} src={tridonicWhiteIcon} />;
  }
};

export default LogoIcon;
