import React from 'react';
import { Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { LightBox } from '@backstage/plugin-techdocs-module-addons-contrib';
import { TextSize } from '@backstage/plugin-techdocs-module-addons-contrib';
import { ExpandableNavigation } from '@backstage/plugin-techdocs-module-addons-contrib';


import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';

import { microsoftAuthApiRef } from '@backstage/core-plugin-api';
// import { gitlabAuthApiRef } from '@backstage/core-plugin-api';
import { SignInPage } from '@backstage/core-components';

import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';
import { ExplorePage } from '@backstage/plugin-explore';
import { DevToolsPage } from '@backstage/plugin-devtools';
import { customDevToolsPage } from './components/devtools/CustomDevToolsPage';

import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_OWNED_BY,
  RELATION_OWNER_OF,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';

export { grafanaPlugin } from '@k-phoen/backstage-plugin-grafana';

import {
  createTheme,
  darkTheme,
  genPageTheme,
//  lightTheme,
  shapes,
} from '@backstage/theme';

import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
//import LightIcon from '@material-ui/icons/WbSunny';
import Brightness2Icon from '@material-ui/icons/Brightness2';

import * as plugins from './plugins';

const colorVariants: Record<string, string[]> = {
  darkGrey: ['#171717', '#383838'],
  marineBlue: ['#006D8F', '#0049A1'],
  veryBlue: ['#0027AF', '#270094'],
  rubyRed: ['#98002B', '#8D1134'],
  toastyOrange: ['#BE2200', '#A41D00'],
  purpleSky: ['#8912CA', '#3E00EA'],
  eveningSea: ['#00FFF2', '#035355'],
  teal: ['#005B4B'],
  pinkSea: ['#C8077A', '#C2297D'],
  greens: ['#4BB8A5', '#187656'],
  baseColors: ['#ececf2', '#a1a1bf'],
  apisColors: ['#8875c1', '#a1a1bf'],
  datasetColors: ['#5379ff', '#642cff']
};

//const tridonicFontColor: string = "#4918ac"

// const myLightTheme = createTheme({
//   palette: {
//     ...lightTheme.palette,
//     primary: {
//       main: '#372A86',
//     },
//     secondary: {
//       main: '#9191bd',
//     },
//     background: {
//       default: '#f1f1f1',
//       paper: '#fafafc',
//     },
//     banner: {
//       info: '#34548a',
//       error: '#ececf2',
//       text: '#FFFFFF',
//       link: '#a595c4',
//     },
//     errorBackground: '#ececf2',
//     warningBackground: '#8a9fbd',
//     infoBackground: '#4918ac',
//     navigation: {
//       background: '#ececf2',
//       indicator: '#4918ac',
//       color: '#372A86',
//       selectedColor: '#262629',
//       navItem: {
//         hoverBackground: '#f4f4f8',
//       },
//     },
//   },
//   defaultPageTheme: 'home',
//   /* below drives the header colors */
//   pageTheme: {
//     home: genPageTheme({ colors: colorVariants.baseColors, shape: shapes.wave, options: { fontColor: tridonicFontColor } }),
//     documentation: genPageTheme({
//       colors: colorVariants.baseColors,
//       shape: shapes.wave2,
//       options: { fontColor: tridonicFontColor }
//     }),
//     tool: genPageTheme({ colors: colorVariants.baseColors, shape: shapes.round, options: { fontColor: tridonicFontColor } }),
//     service: genPageTheme({
//       colors: colorVariants.marineBlue,
//       shape: shapes.wave,
//     }),
//     website: genPageTheme({
//       colors: colorVariants.baseColors,
//       shape: shapes.wave,
//       options: { fontColor: tridonicFontColor }
//     }),
//     library: genPageTheme({
//       colors: colorVariants.veryBlue,
//       shape: shapes.wave,
//     }),
//     other: genPageTheme({ colors: colorVariants.baseColors, shape: shapes.wave, options: { fontColor: tridonicFontColor } }),
//     app: genPageTheme({ colors: colorVariants.baseColors, shape: shapes.wave, options: { fontColor: tridonicFontColor } }),
//     apis: genPageTheme({ colors: colorVariants.apisColors, shape: shapes.wave, }),
//     card: genPageTheme({ colors: colorVariants.veryBlue, shape: shapes.wave, }),
//     openapi: genPageTheme({ colors: colorVariants.apisColors, shape: shapes.wave, }),
//     dataset: genPageTheme({ colors: colorVariants.datasetColors, shape: shapes.wave, }),
//   },
// });

const myDarkTheme = createTheme({
  palette: darkTheme.palette,
  defaultPageTheme: 'home',
  pageTheme: {
    home: genPageTheme({ colors: ['#404040', '#2c0e67'], shape: shapes.wave, options: { fontColor: "#FFFFFF" } }),
    documentation: genPageTheme({
      colors: ['#404040', '#2c0e67'],
      shape: shapes.wave2,
      options: { fontColor: "#FFFFFF" }
    }),
    tool: genPageTheme({ colors: ['#404040', '#2c0e67'], shape: shapes.round, options: { fontColor: "#FFFFFF" } }),
    service: genPageTheme({
      colors: colorVariants.marineBlue,
      shape: shapes.wave,
      options: { fontColor: "#FFFFFF" }
    }),
    website: genPageTheme({
      colors: ['#404040', '#2c0e67'],
      shape: shapes.wave,
      options: { fontColor: "#FFFFFF" }
    }),
    library: genPageTheme({
      colors: colorVariants.veryBlue,
      shape: shapes.wave,
      options: { fontColor: "#FFFFFF" }
    }),
    other: genPageTheme({ colors: ['#404040', '#2c0e67'], shape: shapes.wave, options: { fontColor: "#FFFFFF" } }),
    app: genPageTheme({ colors: ['#404040', '#2c0e67'], shape: shapes.wave, options: { fontColor: "#FFFFFF" } }),
    apis: genPageTheme({ colors: ['#404040', '#2c0e67'], shape: shapes.wave, options: { fontColor: "#FFFFFF" } }),
    openapi: genPageTheme({ colors: colorVariants.apisColors, shape: shapes.wave, }),
    dataset: genPageTheme({ colors: ['#5379ff', '#642cff'], shape: shapes.wave, }),
  },
});

const app = createApp({
  apis,
  plugins: Object.values(plugins),
  components: {
    SignInPage: props => (
      <SignInPage
        {...props}
        providers={
          [
            {
              id: 'azure-auth-provider',
              title: 'Microsoft Active Directory',
              message: 'Sign in to Developer Portal using your Active Directory account.',
              apiRef: microsoftAuthApiRef,
            },
            // {
            //   id: 'gitlab-auth-provider',
            //   title: 'GitLab',
            //   message: 'Sign in using GitLab',
            //   apiRef: gitlabAuthApiRef,
            // }
          ]}
      />
    ),
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  themes: [
    // {
    //   id: 'light-theme',
    //   title: 'Light Theme',
    //   variant: 'light',
    //   icon: <LightIcon />,
    //   Provider: ({ children }) => (
    //     <ThemeProvider theme={myLightTheme}>
    //       <CssBaseline>{children}</CssBaseline>
    //     </ThemeProvider>
    //   ),
    // },
    {
      id: 'dark-theme',
      title: 'Dark Theme',
      variant: 'dark',
      icon: <Brightness2Icon />,
      Provider: ({ children }) => (
        <ThemeProvider theme={myDarkTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    }]
});

const routes = (
  <FlatRoutes>
    {/* <Route path="/" element={<Navigate to="catalog" />} /> */}
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ExpandableNavigation />
        <ReportIssue />
        <TextSize />
        <LightBox />
      </TechDocsAddons>
    </Route>
    <Route path="/explore" element={<ExplorePage />} />
    <Route path="/create" element={<ScaffolderPage
      groups={[
        {
          title: "Recommended",
          filter: entity =>
            entity?.metadata?.tags?.includes('recommended') ?? false,
        },
        {
          title: "Existing Repos",
          filter: entity =>
            entity?.metadata?.tags?.includes('merge-request') ?? false,
        },
      ]} />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route
      path="/catalog-graph"
      element={
        <CatalogGraphPage
          initialState={{
            maxDepth: 1,
            selectedKinds: ['component', 'domain', 'system', 'api', 'group'],
            selectedRelations: [
              RELATION_OWNER_OF,
              RELATION_OWNED_BY,
              RELATION_CONSUMES_API,
              RELATION_API_CONSUMED_BY,
              RELATION_PROVIDES_API,
              RELATION_API_PROVIDED_BY,
              RELATION_HAS_PART,
              RELATION_PART_OF,
              RELATION_DEPENDS_ON,
              RELATION_DEPENDENCY_OF,
            ],
          }}
        />
      }
    />
    <Route path="/devtools" element={<DevToolsPage />} >
      {customDevToolsPage}
    </Route>

  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
