import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/styles'
import { BackstageTheme } from '@backstage/theme'
import tridonicLogo from './logo/tridonic-logo.png';
import tridonicWhiteLogo from './logo/tridonic-white-logo.png';

const useStyles = makeStyles({
  img: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: '#7df3e1',
  },
});
const LogoFull = () => {
  const classes = useStyles();
  const themeInUse = (useTheme() as BackstageTheme).palette.type

  if (themeInUse === "light") {
    return <img className={classes.img} src={tridonicLogo} />;
  }
  else {
    return <img className={classes.img} src={tridonicWhiteLogo} />;
  }
};

export default LogoFull;
