import {
  HomePageToolkit,
  HomePageStarredEntities,
  ComponentAccordion
} from '@backstage/plugin-home';
import { wrapInTestApp, TestApiProvider } from '@backstage/test-utils';
import { Content, Page, InfoCard, Header, MarkdownContent } from '@backstage/core-components';
import {
  starredEntitiesApiRef,
  MockStarredEntitiesApi,
  entityRouteRef,
  catalogApiRef,
} from '@backstage/plugin-catalog-react';
import { configApiRef } from '@backstage/core-plugin-api';
import { ConfigReader } from '@backstage/config';
import { HomePageSearchBar, searchPlugin } from '@backstage/plugin-search';
import {
  searchApiRef,
  SearchContextProvider,
} from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import React, { ComponentType } from 'react';
import LogoIcon from '../Root/LogoIcon';

const entities = [
  {
    apiVersion: '1',
    kind: 'Component',
    metadata: {
      name: 'mock-starred-entity',
      title: 'Mock Starred Entity!',
    },
  },
  {
    apiVersion: '1',
    kind: 'Component',
    metadata: {
      name: 'mock-starred-entity-2',
      title: 'Mock Starred Entity 2!',
    },
  },
  {
    apiVersion: '1',
    kind: 'Component',
    metadata: {
      name: 'mock-starred-entity-3',
      title: 'Mock Starred Entity 3!',
    },
  },
  {
    apiVersion: '1',
    kind: 'Component',
    metadata: {
      name: 'mock-starred-entity-4',
      title: 'Mock Starred Entity 4!',
    },
  },
];

const mockCatalogApi = {
  getEntities: async () => ({ items: entities }),
};


const starredEntitiesApi = new MockStarredEntitiesApi();
starredEntitiesApi.toggleStarred('component:default/example-starred-entity');
starredEntitiesApi.toggleStarred('component:default/example-starred-entity-2');
starredEntitiesApi.toggleStarred('component:default/example-starred-entity-3');
starredEntitiesApi.toggleStarred('component:default/example-starred-entity-4');

const markdown =
  '## Tridonic documentation links\n' +
  '\n' +
  '1. Available soon...\n' +
  '2. Available soon...\n' +
  '3. Available soon...\n';

export default {
  title: 'Plugins/Home/Templates',
  decorators: [
    (Story: ComponentType<{}>) =>
      wrapInTestApp(
        <>
          <TestApiProvider
            apis={[
              [catalogApiRef, mockCatalogApi],
              [starredEntitiesApiRef, starredEntitiesApi],
              [searchApiRef, { query: () => Promise.resolve({ results: [] }) }],
              [
                configApiRef,
                new ConfigReader({
                  stackoverflow: {
                    baseUrl: 'https://api.stackexchange.com/2.2',
                  },
                }),
              ],
            ]}
          >
            <Story />
          </TestApiProvider>
        </>,
        {
          mountedRoutes: {
            '/hello-company': searchPlugin.routes.root,
            '/catalog/:namespace/:kind/:name': entityRouteRef,
          },
        },
      ),
  ],
};

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: '8px 0',
    borderRadius: '0px',
    margin: 'auto',
  },
}));

const MarkdownContentGithubFlavoredCommonMark = () => (
  < MarkdownContent content={markdown} dialect="gfm" />
);

const ToolkitInAccordian = () => {
  return (
    <InfoCard title="Toolkit" noPadding>
      <Grid item>
        <HomePageToolkit
          title="Python"
          tools={Array(5).fill({
            url: '#',
            label: 'link',
            icon: <LogoIcon />,
          })}
          Renderer={ComponentAccordion}
        />
        <HomePageToolkit
          title="React"
          tools={Array(2).fill({
            url: '#',
            label: 'link',
            icon: <LogoIcon />,
          })}
          Renderer={ComponentAccordion}
        />
        <HomePageToolkit
          title="C Components"
          tools={Array(3).fill({
            url: '#',
            label: 'link',
            icon: <LogoIcon />,
          })}
          Renderer={ComponentAccordion}
        />
      </Grid>
    </InfoCard>
  );
};

export const HomePage = () => {
  const classes = useStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <Header
              title="DEVELOPER PORTAL"
              style={{ textAlign: "center", height: "10em", marginBottom: "3em" }}
            />
            <Grid container item xs={12} alignItems="center" direction="row">
              <HomePageSearchBar
                classes={{ root: classes.searchBar }}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <HomePageStarredEntities />
              </Grid>
              <Grid item xs={12} md={6}>
                <InfoCard title="Tridonic Developer Portal Getting-Started">
                  {/* placeholder for content */}
                  {MarkdownContentGithubFlavoredCommonMark()}
                  {/* <div style={{ height: 370 }} /> */}
                </InfoCard>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                {ToolkitInAccordian()}
              </Grid>
            </Grid>
            
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider >
  );
};
